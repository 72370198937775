<template lang="pug">
div(class="d-flex flex-column main-container" v-if="!loading" style="height:90vh;overflow-y: auto; gap: 1rem;")
    div(class="d-flex flex-column w-100 p-2 mt-2" v-if="!loading && event.crb5c_eventname")     
        //- b-button(@click="showSuccessModal = true" variant="primary" class="w-100") Submit
        event-submission-card(:event="event?.userSubmission.data" v-if="event.crb5c_eventname && event.submitted && !event.notAttending" @showSubmissionModal="showSubmissionDetails = true" :gst="event.event.gst")
        b-card(:title="event.crb5c_eventname" class="")
            div(class="d-flex align-items-center" style="gap: 10px;").my-2
                div(class="badge" :class="getEventStatus(event).class")
                    span {{ getEventStatus(event).text }} 
                div(class="badge" :class="{ 'badge-primary': event.submitted && !event.notAttending, 'badge-danger': !event.submitted || event.notAttending}")
                    span {{ event.notAttending ? "Not Attending" : event.submitted ? "Attending" : "Not Submitted" }}
            hr 
            div(v-html="event.event?.description").my-2
            b-card-text.mt-3.ml-1.h6 Event Details:
            b-card-text.badge-local.mt-2.mb-0(class="d-flex align-items-center" style="gap: 0.5rem;width:fit-content;" v-if="event.event.isSingleDay && event.event.singleDay && event.event.singleDay.pricePerPerson !== 0" ) 
                b-icon-cash-coin
                span {{ `$ ${event.event.singleDay.pricePerPerson}` }} 
            b-card-text.badge.badge-warning.d-flex.align-items-start.w-100.mt-2.mb-0(style="gap: 0.5rem;white-space:wrap;text-align:left;" v-if="event.event.isSingleDay && event.event.singleDay && event.event.singleDay.pricePerPerson !== 0")
                b-icon(icon="lightbulb-fill")
                span.flex-grow-1.text-start Cost is before GST for Accompanying Caregiver(s) only.
            //- b-card-text.badge-local.my-2(style="width:fit-content;") Event Type: {{ event.event.isSingleDay ? "Single Day" :"Multiple Days" }}
            div(class="d-flex align-items-start flex-column" style="gap: 0.5rem; flex-wrap: wrap; white-space: nowrap;") 
                b-card-text.m-0.badge-local(class="d-flex align-items-center flex-wrap mt-2" style="gap: 0.5rem;white-space: nowrap;") 
                    b-icon-calendar2-date-fill
                    div  
                        span(v-if="!event.event?.isSingleDay" ) From: 
                        span {{ new Date(event.crb5c_date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                b-card-text.m-0.badge-local(class="d-flex align-items-center flex-wrap" style="gap: 0.5rem;white-space: nowrap;" v-if="!event.event?.isSingleDay && event.event?.multipleEvents.length > 0") 
                    b-icon-calendar2-date-fill
                    div  
                        span() To: 
                        span()  {{ new Date(event.event.multipleEvents[event.event.multipleEvents.length - 1].date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                b-card-text.badge-local(class="d-flex align-items-center mb-0" style="gap: 0.5rem;white-space: nowrap;" v-if="event?.event?.isSingleDay")
                    b-icon-clock-fill
                    //- span {{ event.event.isSingleDay ? event.event.singleDay.time : event.event.multipleEvents[0].time }}
                    span {{event?.event?.isSingleDay && typeof event?.event?.singleDay?.time === 'object' ? `${event?.event?.singleDay?.time?.hh}:${event?.event?.singleDay?.time?.mm} ${event?.event?.singleDay?.time?.A}` : event?.event?.singleDay?.time}}
                    span {{ !event.event.isSingleDay && typeof event?.event?.multipleEvents[0]?.time === 'object' ? `${event?.event?.multipleEvents[0]?.time?.hh}:${event?.event?.multipleEvents[0]?.time?.mm} ${event?.event?.multipleEvents[0]?.time?.A}` : event?.event?.multipleEvents[0]?.time }}
                b-card-text.badge.badge-success(class="d-flex align-items-center py-2 mb-0" style="gap: 0.5rem;white-space: nowrap;" v-if="event.event.isSingleDay && event.event.singleDay && event.event.singleDay.pricePerPerson !== 0") 
                    b-icon(icon="lightbulb-fill")
                    span.m-0 Client cost is covered
            hr 
            div(v-if="event.event.isSingleDay && event.event.singleDay" class="d-flex flex-column" style="gap: 1rem;") 
                div(class="itineraries" v-if="event.event.singleDay.itineraries.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Itineraries
                    div(v-for="itinerary in event.event.singleDay.itineraries" :key="itinerary.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-arrow-right-circle-fill
                        b-card-text.m-0 {{ itinerary.time }} - {{ itinerary.description }}
                div(v-if="event.event.singleDay.food.lunch.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Lunch Options (Scroll Below to Select One)
                    div(v-for="lunch in event.event.singleDay.food.lunch" :key="lunch.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-egg-fried
                        b-card-text.m-0 {{ lunch.name }}
                div(v-if="event.event.singleDay.food.beverages.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Beverages (Scroll Below to Select One)
                    div(v-for="beverage in event.event.singleDay.food.beverages" :key="beverage.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                        b-icon-cup-straw
                        b-card-text.m-0 {{ beverage.name }}
            hr(v-if="!event.submitted && event.event.isSingleDay && event.event.singleDay")
            div(v-if="!event.submitted && event.event.isSingleDay && event.event.singleDay" class="d-flex flex-column food-selector" style="gap: 1rem;")
                b-card-text.mt-3.h6 Select Who will be attending
                b-form-checkbox-group(v-model="foodSelected.singleDay.whoGoing" :options="peopleGoingOptions")
                div(v-if="foodSelected.singleDay.whoGoing.includes('Client') && event.event.singleDay.food.lunch.length>0" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                    b-card-text.h6 Select Lunch Option for Client
                    b-form-radio-group(v-model="foodSelected.singleDay.client.lunch" :options="event.event.singleDay.food.lunch.map(lunch => lunch.name)" :key="event.event.singleDay.food.lunch.map(lunch => lunch.id).join(',')")
                    div(v-if="event.event.singleDay.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                        b-card-text.mt-3.h6 Select Beverage Option for Client
                        b-form-radio-group(v-model="foodSelected.singleDay.client.beverage" :options="event.event.singleDay.food.beverages.map(beverage => beverage.name)" :key="event.event.singleDay.food.beverages.map(beverage => beverage.id).join(',')")


                div(v-if="foodSelected.singleDay.whoGoing.includes('Caregiver') && event.event.singleDay.food.lunch.length>0" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                    b-card-text.h6 Select Lunch Option for Caregiver
                    b-form-radio-group(v-model="foodSelected.singleDay.caregiver.lunch" :options="event.event.singleDay.food.lunch.map(lunch => lunch.name)" :key="event.event.singleDay.food.lunch.map(lunch => lunch.id).join(',')")
                    div(v-if="event.event.singleDay.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                        b-card-text.mt-3.h6 Select Beverage Option for Caregiver
                        b-form-radio-group(v-model="foodSelected.singleDay.caregiver.beverage" :options="event.event.singleDay.food.beverages.map(beverage => beverage.name)" :key="event.event.singleDay.food.beverages.map(beverage => beverage.id).join(',')")
                div(v-if="foodSelected.singleDay.whoGoing.includes('Other Persons')" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                    b-card-text.h6(v-if="event.event.singleDay.food.lunch.length>0") Select Lunch Option for Other Persons
                    div(v-if="foodSelected.singleDay.otherPersons.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                        b-card-text.h6 Other Persons
                        div(v-for="person in foodSelected.singleDay.otherPersons" :key="person.name" class="d-flex flex-column" style="gap: 0.5rem; border: 1px solid #ccc; padding: 1rem;")
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon(icon="person-badge")
                                b-card-text.m-0 {{ person.name }}
                            div(class="d-flex flex-column" v-if="event.event.singleDay.food.lunch.length>0")
                                b-card-text.mt-3.h6 Select Lunch Option for {{ person.name }}
                                b-form-radio-group(v-model="person.lunch" :options="event.event.singleDay.food.lunch.map(lunch => lunch.name)" :key="event.event.singleDay.food.lunch.map(lunch => lunch.id).join(',')")
                                div(v-if="event.event.singleDay.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                                    b-card-text.mt-3.h6 Select Beverage Option for {{ person.name }}
                                    b-form-radio-group(v-model="person.beverage" :options="event.event.singleDay.food.beverages.map(beverage => beverage.name)" :key="event.event.singleDay.food.beverages.map(beverage => beverage.id).join(',')")
                                b-button(@click="deleteOtherPersonSingleDay(person.id)" variant="danger").w-100.my-2 Remove Person
                    b-form-group(label="Enter Name of Other Person")
                        b-form-input(v-model="tempOtherPersonName" type="text").mb-2
                        b-button(@click="addOtherPersonSingleDay" variant="primary").w-100 Add Person
            hr(v-if="!event.submitted && event.event.isSingleDay && event.event.singleDay")
            
            div(v-if="!event.event.isSingleDay && event.event.multipleEvents.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                div(class="multipleDay-itineraries" v-if="event.event.multipleEvents.length > 0" style="gap: 1rem;")
                    b-card-text.my-2.h6 Details about each day
                    div(v-for="day in event.event.multipleEvents" :key="day.id" style="border: 1px solid #ccc; padding: 1rem;").my-1
                        div(class="d-flex flex-column" style="gap: 0.5rem;")
                            b-card-text.my-2.h6 {{ day.title }} 
                            div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-calendar2-date-fill
                                b-card-text.m-0 {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                        div(class="align-items-center" style="gap: 0.5rem;")
                            b-card-text.m-0(class="d-flex align-items-center mt-2" style="gap: 0.5rem;" v-if="day.pricePerPerson !== 0") 
                                b-icon-cash-coin
                                span Cost:  $ {{ day.pricePerPerson ?? "0" }} 
                            b-card-text.badge.badge-warning.d-flex.align-items-start.w-100.py-1.my-3(style="gap: 0.5rem;white-space:wrap;text-align:left;" v-if="day.pricePerPerson !== 0")
                                b-icon(icon="lightbulb-fill")
                                span.flex-grow-1.text-start Cost is before GST for Accompanying Caregiver(s) only.
                        b-card-text.mt-3.h6(v-if="day.itineraries.length > 0") Itineraries
                            
                        div(class="d-flex align-items-center" style="gap: 0.5rem;" v-for="itinerary in day.itineraries").my-1
                            b-icon-arrow-right-circle-fill
                            b-card-text.m-0 {{ itinerary.time }} - {{ itinerary.description }}
                        //- div(v-if="day.itineraries.length === 0").my-1
                        //-     b-card-text No Itineraries for this day
                        div(v-if="day.food.lunch.length > 0")
                            b-card-text.mt-3.h6 Lunch Options (Scroll Below to Select One)
                            div(v-for="lunch in day.food.lunch" :key="lunch.id" class="d-flex align-items-center" style="gap: 0.5rem;" )
                                b-icon-egg-fried
                                b-card-text.m-0 {{ lunch.name }}
                        div(v-if="day.food.beverages.length > 0" style="gap: 1rem;")
                            b-card-text.my-2.h6 Beverages (Scroll Below to Select One)
                            div(v-for="beverage in day.food.beverages" :key="beverage.id" class="d-flex align-items-center" style="gap: 0.5rem;")
                                b-icon-cup-straw
                                b-card-text.m-0 {{ beverage.name }}

                div(v-if="!event.submitted && !event.event.isSingleDay && event.event.multipleEvents.length > 0")
                    
                    b-card-text.mt-3.h6 Select who will be accompanying Client
                    b-form-checkbox-group(v-model="foodSelected.multipleDays.whoGoing" :options="peopleGoingOptions").mb-4
                    div(v-if="foodSelected.multipleDays.whoGoing.includes('Client')" class="d-flex flex-column whichDay-selection" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mb-4
                        b-card-text.h6.text-primary Select Days for Client
                        b-form-checkbox-group(v-model="foodSelected.multipleDays.client.attendingDays" :options="event.event.multipleEvents.filter(day => new Date(day.date) >= new Date()).map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.event.multipleEvents.map(day => day.id).join(',')" ) 
                        div(v-for="(day, index) in event.event.multipleEvents" :key="day.id" v-if="foodSelected.multipleDays.client.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column")
                            div(v-if="day.food.lunch.length > 0"  class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group( :options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, foodSelected.multipleDays.client.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'client')")
                            div(v-if="day.food.beverages.length > 0"   class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group( :options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, foodSelected.multipleDays.client.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'client')")



                    div(v-if="foodSelected.multipleDays.whoGoing.includes('Caregiver')" class="d-flex flex-column whichDay-selection" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mb-4
                        b-card-text.h6 Select Days for Caregiver
                        b-form-checkbox-group(v-model="foodSelected.multipleDays.caregiver.attendingDays" :options="event.event.multipleEvents.filter(day => new Date(day.date) >= new Date()).map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.event.multipleEvents.map(day => day.id).join(',')") 
                        div(v-for="(day, index) in event.event.multipleEvents" :key="day.id" v-if="foodSelected.multipleDays.caregiver.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column" )
                            div(v-if="day.food.lunch.length > 0" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group(:options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, foodSelected.multipleDays.caregiver.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'caregiver')")
                            div(v-if="day.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;")
                                b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                b-form-radio-group(:options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, foodSelected.multipleDays.caregiver.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'caregiver')")
                    div(v-if="foodSelected.multipleDays.whoGoing.includes('Other Persons')" class="d-flex flex-column whichDay-selection" style="gap: 1rem; border: 1px solid #ccc; padding: 1rem;").mb-4

                        b-card-text Add Other Persons
                        b-form-group(label="Enter Name of Other Person")
                            b-form-input(v-model="tempOtherPersonName" type="text").mb-2
                            b-button(@click="addOtherPersonMultipleDays" variant="primary").w-100 Add Person
                        div(v-if="foodSelected.multipleDays.otherPersons.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                            b-card-text.h6 Other Persons
                            div(v-for="person in foodSelected.multipleDays.otherPersons" :key="person.name" class="d-flex flex-column").my-1
                                div(class="d-flex align-items-center" style="gap: 0.5rem;")
                                    b-icon-person-badge
                                    b-card-text.m-0 {{ person.name }}
                                div(class="d-flex flex-column")
                                    b-card-text.mt-3.h6 Select Days for {{ person.name }}
                                    b-form-checkbox-group(v-model="person.attendingDays" :options="event.event.multipleEvents.filter(day => new Date(day.date) >= new Date()).map(day => new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" :key="event.event.multipleEvents.map(day => day.id).join(',')") 
                                    div(v-for="(day, index) in event.event.multipleEvents" :key="day.id" v-if="person.attendingDays.includes(new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}))" class="d-flex flex-column" ).my-1
                                        div(v-if="day.food.lunch.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                                            b-card-text.h6 Select Lunch Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                            b-form-radio-group(:options="day.food.lunch.map(lunch => lunch.name)" :key="day.food.lunch.map(lunch => lunch.id).join(',')" :value="getLunchOptionForDay(day.id, foodSelected.multipleDays.client.lunchByDay)" @change="updateLunchOptionForDay(day.id, $event, 'otherPersons', person.id)")
                                        div(v-if="day.food.beverages.length > 0" class="d-flex flex-column" style="gap: 1rem;")
                                            b-card-text.mt-3.h6 Select Beverage Option for {{ new Date(day.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) }}
                                            b-form-radio-group(:options="day.food.beverages.map(beverage => beverage.name)" :key="day.food.beverages.map(beverage => beverage.id).join(',')" :value="getBeverageOptionForDay(day.id, foodSelected.multipleDays.client.beverageByDay)" @change="updateBeverageOptionForDay(day.id, $event, 'otherPersons', person.id)")
                                    
                                    b-button(@click="deletePersonMultipleDay(person.id)" variant="danger").w-100.my-2 Remove Person 


            div(class="d-flex flex-column w-100 my-2" style="gap: 1rem;" v-if="!loading && !event.submitted && event.crb5c_eventname")
                textarea(v-model="foodSelected.remarks"  rows="3" class="rounded-lg p-1 border border-primary"
                :placeholder="event?.event?.remarksPlaceholder ? event?.event?.remarksPlaceholder : 'Add Remarks (Optional)'"
                ).mb-2
            div(v-if="!event.submitted && event.event.isSingleDay && event.event.singleDay" class="d-flex flex-column" style="gap: 1rem;").mb-4
                b-card-text.mt-3.h6(v-if="event.event.singleDay.pricePerPerson !== 0")
                    span() Total Price: $ {{  (Number(singleDayTotalCost) + (Number(singleDayTotalCost) * (Number(event.event.gst)/100))).toFixed(2) }}
                        span.mx-1(v-if="singleDayTotalCost") (after GST)
            div(v-if="event.submitted && event.notAttending" class="d-flex " style="gap: 1rem;")
                b-card-text.my-2.h5 You Responded: 
                    b-card-text.ml-2.badge.badge-danger(style="width:fit-content;") Not Attending

            div(v-if="!loading && !event.submitted &&  !event.event.isSingleDay && event.event.multipleEvents.length > 0 && multipleDaysTotalCost !==0" class="d-flex flex-column" style="gap: 1rem;").mb-4
                b-card-text.mt-3.h6 Total Price: 
                    span() $ {{  (multipleDaysTotalCost + ( multipleDaysTotalCost * (event.event.gst/100))).toFixed(2) }}
                        span.mx-1(v-if="multipleDaysTotalCost") (after GST)

            div(class="d-flex flex-column w-100" style="gap: 1rem;" v-if="!loading && !event.submitted && event.crb5c_eventname")
                //- p.small Submission has been disabled for a while. Please check back later or contact the admin for more information.
                b-button(variant="danger " @click="cancelEvent" v-if="!hideNotAttending"
                :disabled="loading || loadingSubmit") Not Attending
                b-button(variant="primary w-100" @click="showCautionModal" :disabled="loading || loadingSubmit") 
                    span(v-if="loadingSubmit") Submitting...
                    span(v-else) Submit
                small(class="text-sm") To not attend the event, click on Not Attending button(hidden if user is selected or day is selected)

            b-button(v-if="!loading && event.submitted && !event.notAttending && event.crb5c_eventname" variant="danger"  @click="showCancelEventModal = true" class="w-100 my-2") Cancel Submission
    PreSubmissionModal(:show.sync="showPreSubmissionModal" @close="showPreSubmissionModal = false" :event="event?.event" :submission="foodSelected" v-if="showPreSubmissionModal"  :totalMultipleDaysCost="multipleDaysTotalCost" :totalSingleDayCost="singleDayTotalCost" @submit="submit")
    SuccessModal(:show.sync="showSuccessModal" @close="showSuccessModal = false" v-if="showSuccessModal" :reloadFunction="loadEventDetails")
    ShowSubmissionDetailModal(:show.sync="showSubmissionDetails" @close="showSubmissionDetails = false" :event="event?.event" v-if="showSubmissionDetails && event?.submitted && !event?.notAttending" :submission="event?.userSubmission?.data" )
    CancelEventSubmissionModal(:show.sync="showCancelEventModal" @close="showCancelEventModal = false" :submission="event?.userSubmission?.data" v-if="showCancelEventModal" :event="event" :reloadFunction="loadEventDetails")
    CautionModalCaregiver(:show.sync="showCautionsModal" @close="showCautionsModal = false" v-if="showCautionsModal" @showConfirmation = "showConfirmationModalFunc" )
div(v-else class="d-flex w-100 flex-column p-2")
    b-skeleton(width="100%" height="10vh")
    div(class="hr my-2" style="height: 1px; background-color: #ccc; width: 100%") 
    b-skeleton(width="100%" height="40vh")
    div(class="hr my-2" style="height: 1px; background-color: #ccc; width: 100%") 
    b-skeleton(width="100%" height="20vh")
    

</template>

<script>
/* eslint-disable */
import axios from "axios";
import { v4 as uuid } from "uuid";
import PreSubmissionModal from "@/components/PreSubmissionModal.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import { validateEvent } from "@/utils/eventValidation";
import EventSubmissionCard from "@/components/EventSubmissionCard.vue";
import ShowSubmissionDetailModal from "@/components/ShowSubmissionDetailModal.vue";
import CancelEventSubmissionModal from "@/components/CancelEventSubmissionModal.vue";
import CautionModalCaregiver from "@/components/CautionModalCaregiver.vue";
export default {
    components: {
        PreSubmissionModal,
        SuccessModal,
        EventSubmissionCard,
        ShowSubmissionDetailModal,
        CancelEventSubmissionModal,
        CautionModalCaregiver
        
    },
    data() {
        return {
            showCautionsModal: false,
            showCancelEventModal: false,
            showSubmissionDetails: false,
            showSuccessModal: false,
            showPreSubmissionModal: false,
            event: {},
            loading: false,
            peopleGoingOptions: [
        { text: "Client", value: "Client", disabled: false },
        { text: "Caregiver", value: "Caregiver" },
        { text: "Other Persons", value: "Other Persons" }
      ],
            tempOtherPersonName: "",
            loadingSubmit: false,
            foodSelected: {
                singleDay: {
                    whoGoing: [],
                    client: {
                        lunch: "",
                        beverage: "",
                    },
                    caregiver: {
                        lunch: "",
                        beverage: "",
                    },
                    otherPersons: []
                },
                multipleDays: {
                    whoGoing: ["Client"],
                    client: {
                        attendingDays: [],
                        lunchByDay: [
                            
                        ],
                        beverageByDay: [
                        
                        ]

                    },
                    caregiver: {
                        attendingDays: [],
                        lunchByDay: [],
                        beverageByDay: []
                    },
                    otherPersons: []



                },
                remarks:""
            },
            hideNotAttending: false
        };
    },
    computed: {
        multipleDaysTotalCost() {
            let total = 0;
            
            if (this.foodSelected.multipleDays.whoGoing.includes('Caregiver')) {
                for (let day of this.foodSelected.multipleDays.caregiver.attendingDays) {
                    let dayIndex = this.event.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                    if (dayIndex !== -1) {
                        total += parseFloat(this.event.event.multipleEvents[dayIndex].pricePerPerson);
                    }
                }
            }
            if (this.foodSelected.multipleDays.whoGoing.includes('Other Persons')) {
                for (let person of this.foodSelected.multipleDays.otherPersons) {
                    for (let day of person.attendingDays) {
                        let dayIndex = this.event.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                        if (dayIndex !== -1) {
                            total += parseFloat(this.event.event.multipleEvents[dayIndex].pricePerPerson);
                        }
                    }
                }
            }
            return total;
        },
        singleDayTotalCost() {
            let total = 0;
            
            if (this.foodSelected.singleDay.whoGoing.includes('Caregiver')) {
                total += parseFloat(this.event.event.singleDay.pricePerPerson);
            }
            if (this.foodSelected.singleDay.whoGoing.includes('Other Persons')) {
                total += this.foodSelected.singleDay.otherPersons.length * parseFloat(this.event.event.singleDay.pricePerPerson);
            }
            return total;
        },
        
    },
    methods: {
        getLastDay() {
            return this.event.event.multipleEvents[this.event.event.multipleEvents.length - 1].date;
        },
        getEventStatus(){
            const currentDate = new Date();
            const eventStartDate = new Date(this.event.crb5c_date);
            const eventEndDate = new Date(this.getLastDay);

            if (eventStartDate > currentDate) {
                return { text: 'Upcoming', class: 'badge-success' };
            } else if (eventEndDate < currentDate) {
                return { text: 'Past', class: 'badge-danger' };
            } else {
                return { text: 'Ongoing', class: 'badge-primary' }; 
            }
        },
        async showCautionModal() {
            const validation = validateEvent(this.event, this.foodSelected);
            if (!validation.isValid) {
                this.$bvToast.toast(validation.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                });
                return;
            }
            if (this.event?.event.isSingleDay) {
                if (
                    !this.foodSelected?.singleDay?.whoGoing.includes("Caregiver") &&

                    this.foodSelected?.singleDay?.otherPersons.length === 0) {
                    this.showCautionsModal = true;
                } else {
                    this.showPreSubmissionModal = true;
                }
            } else {
                if (
                    !this.foodSelected?.multipleDays?.whoGoing.includes("Caregiver") &&
                    this.foodSelected?.multipleDays?.otherPersons.length === 0) {
                    this.showCautionsModal = true;
                } else {
                    this.showPreSubmissionModal = true;
                }
            }


        },
        async showConfirmationModalFunc(){
            const validation = validateEvent(this.event, this.foodSelected);
            if (!validation.isValid) {
            this.$bvToast.toast(validation.message, {
                title: 'Error',
                variant: 'danger',
                solid: true
            });
            return; 
        }
            this.showPreSubmissionModal = true;
        },
      async  cancelEvent() {
            let choice = confirm("Are you sure you want to cancel this event?");
            if(choice){
                const data = {
                    client: this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid,
                    caregiver: this.$root.currentUser[0].crb5c_fow_caregiverid,
                    json:{
                        eventID: this.event.crb5c_foweventmanagementid,
                        userID: this.$root.currentUser[0].id,
                        status: "Not Attending",
                        caregiverName: this.$root.currentUser[0].crb5c_name,
                        clientName: this.$root.currentUser[0].crb5c_client.crb5c_no,
                        name: this.event.crb5c_eventname,

                    }
                }
                this.loading = true;
                try{
                    const response = await axios.post("/api/events/v2/cancel", data);
                    if(response){
                        this.$bvToast.toast('Marked as Not Attending', {
                            title: 'Success',
                            variant: 'success',
                            solid: true
                        });
                        this.$router.push("/events");
                    }

                }
                catch(err){
                    console.log(err);
                }
                finally{
                    this.loading = false;
                }
            }

        },
        updateLunchOptionForDay(dayID, selectedLunch, personType,personID=null) {
            if(personID){
                const personIndex = this.foodSelected.multipleDays.otherPersons.findIndex(person => person.id === personID);
                if (personIndex !== -1) {
                    const dayIndex = this.foodSelected.multipleDays.otherPersons[personIndex].lunchByDay.findIndex(day => day.dayID === dayID);
                    if (dayIndex !== -1) {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].lunchByDay[dayIndex].lunchOption = selectedLunch;
                    } else {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].lunchByDay.push({ dayID, lunchOption: selectedLunch });
                    }
                }
            }
            else{
            const dayIndex = this.foodSelected.multipleDays[personType].lunchByDay.findIndex(day => day.dayID === dayID);
            if (dayIndex !== -1) {
                
                this.foodSelected.multipleDays[personType].lunchByDay[dayIndex].lunchOption = selectedLunch;
            } else {
                
                this.foodSelected.multipleDays[personType].lunchByDay.push({ dayID, lunchOption: selectedLunch });
            }
        }
        },
        updateBeverageOptionForDay(dayID, selectedBeverage, personType, personID=null) {
            if(personID){
                const personIndex = this.foodSelected.multipleDays.otherPersons.findIndex(person => person.id === personID);
                if (personIndex !== -1) {
                    const dayIndex = this.foodSelected.multipleDays.otherPersons[personIndex].beverageByDay.findIndex(day => day.dayID === dayID);
                    if (dayIndex !== -1) {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].beverageByDay[dayIndex].beverageOption = selectedBeverage;
                    } else {
                        
                        this.foodSelected.multipleDays.otherPersons[personIndex].beverageByDay.push({ dayID, beverageOption: selectedBeverage });
                    }
                }
                // console.log(this.foodSelected)
            }
            else{
            const dayIndex = this.foodSelected.multipleDays[personType].beverageByDay.findIndex(day => day.dayID === dayID);
            if (dayIndex !== -1) {
                
                this.foodSelected.multipleDays[personType].beverageByDay[dayIndex].beverageOption = selectedBeverage;
            } else {
                
                this.foodSelected.multipleDays[personType].beverageByDay.push({ dayID, beverageOption: selectedBeverage });
            }
        }
        },
        getLunchOptionForDay(dayID, lunchByDay) {
            const day = lunchByDay.find(day => day.dayID === dayID);
            return day ? day.lunchOption : null;
        },
        getBeverageOptionForDay(dayID, beverageByDay) {
            const day = beverageByDay.find(day => day.dayID === dayID);
            return day ? day.beverageOption : null;
        },

        async submit() {
            try{
            if (this.event.event.isSingleDay) {
                const validation = validateEvent(this.event, this.foodSelected);
                if (!validation.isValid) {
                    this.$bvToast.toast(validation.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }
                this.loadingSubmit = true;

                const payload = {
                    json: {
                        name: this.event.crb5c_eventname,
                        eventID: this.event.crb5c_foweventmanagementid,
                        totalAttending: this.foodSelected.singleDay.whoGoing.includes('Client') + this.foodSelected.singleDay.whoGoing.includes('Caregiver') + this.foodSelected.singleDay.otherPersons.length,
                        totalCost: this.singleDayTotalCost,
                        caregiverName: this.$root.currentUser[0].crb5c_name,
                        clientName: this.$root.currentUser[0].crb5c_client.crb5c_no,
                        singleDay: {
                            whoGoing: Array.from([...new Set(this.foodSelected.singleDay.whoGoing)]),
                            client: this.foodSelected.singleDay.client,
                            caregiver: this.foodSelected.singleDay.caregiver,
                            otherPersons: this.foodSelected.singleDay.otherPersons
                        },
                        remarks: this.foodSelected.remarks ?? "",
                        gst: this.event.event.gst
                    },
                    client: this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid,
                    caregiver: this.$root.currentUser[0].crb5c_fow_caregiverid,
                    inv: {
                        eventTitle: this.event.crb5c_eventname,
                        client_id: this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid,
                        caregiver_id: this.$root.currentUser[0].crb5c_fow_caregiverid,
                        caregiver: this.$root.currentUser[0].crb5c_name,
                        caregiverAttendingDays: this.foodSelected.singleDay.whoGoing.includes('Caregiver') ? [{
                            date: new Date(this.event.crb5c_date).toISOString().split('T')[0],
                            price: this.event.event.singleDay.pricePerPerson
                        }] : [],
                        others: this.foodSelected.singleDay.whoGoing.includes('Other Persons') ? this.foodSelected.singleDay.otherPersons.map(person => {
                            return {
                                name: person.name,
                                attendingDays: [{
                                    date: new Date(this.event.crb5c_date).toISOString().split('T')[0],
                                    price: this.event.event.singleDay.pricePerPerson
                                }]
                            };
                        }) : [],
                        client: this.$root.currentUser[0].crb5c_client.crb5c_no,
                        total: this.singleDayTotalCost,
                        gst: this.event.event.gst,
                        date: new Date().toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})

                    }

                }
                
                const res = await axios.post('/api/events/v2', payload);
                if (res) {
                    console.log("res", res);
                    this.$bvToast.toast('Submitted event attendance', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    this.loadingSubmit = false;
                    // this.goBack();
                    this.showSuccessModal = true;
                } else {
                    this.$bvToast.toast('Error submitting event attendance', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    this.loadingSubmit = false;

                }

            }
            else{
                const validation = validateEvent(this.event, this.foodSelected);
                if (!validation.isValid) {
                    this.$bvToast.toast(validation.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    return;
                }
                
                const payload = {
                    json: {
                        name: this.event.crb5c_eventname,
                        eventID: this.event.crb5c_foweventmanagementid,
                        totalAttending: this.foodSelected.multipleDays.whoGoing.includes('Client') + this.foodSelected.multipleDays.whoGoing.includes('Caregiver') + this.foodSelected.multipleDays.otherPersons.length,
                        totalCost: this.multipleDaysTotalCost,
                        caregiverName: this.$root.currentUser[0].crb5c_name,
                        clientName: this.$root.currentUser[0].crb5c_client.crb5c_no,
                        multipleDays: {
                            whoGoing: Array.from([...new Set(this.foodSelected.multipleDays.whoGoing)]),
                            client: this.foodSelected.multipleDays.client,
                            caregiver: this.foodSelected.multipleDays.caregiver,
                            otherPersons: this.foodSelected.multipleDays.otherPersons
                        },
                        remarks: this.foodSelected.remarks ?? "",
                        gst: this.event.event.gst
                    },
                    client: this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid,
                    caregiver: this.$root.currentUser[0].crb5c_fow_caregiverid,
                    inv: {
                        eventTitle: this.event.crb5c_eventname,
                        client_id: this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid,
                        caregiver_id: this.$root.currentUser[0].crb5c_fow_caregiverid,
                        caregiver: this.$root.currentUser[0].crb5c_name,
                        caregiverAttendingDays:this.foodSelected.multipleDays.whoGoing.includes("Caregiver") ? this.foodSelected.multipleDays.caregiver.attendingDays.map(day => {
                            const dayIndex = this.event.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                            return {
                                date: day,
                                price: dayIndex !== -1 ? this.event.event.multipleEvents[dayIndex].pricePerPerson : 0
                            };
                        }) : [],
                        others: this.foodSelected.multipleDays.whoGoing.includes('Other Persons') ? this.foodSelected.multipleDays.otherPersons.map(person => {
                            return {
                                name: person.name,
                                attendingDays: person.attendingDays.map(day => {
                                    const dayIndex = this.event.event.multipleEvents.findIndex(eventDay => new Date(eventDay.date).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}) === day);
                                    return {
                                        date: day,
                                        price: dayIndex !== -1 ? this.event.event.multipleEvents[dayIndex].pricePerPerson : 0
                                    };
                                })
                            };
                        }) : [],
                        client: this.$root.currentUser[0].crb5c_client.crb5c_no,
                        total: this.multipleDaysTotalCost,
                        gst: this.event.event.gst,
                        date: new Date().toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})

                    }

                }
                this.loadingSubmit = true;
                // console.log("payload",payload);
                const res = await axios.post('/api/events/v2', payload);
                if (res) {
                    // alert('Submitted event attendance')
                    // console.log("res", res);
                    this.$bvToast.toast('Submitted event attendance', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    });
                    this.loadingSubmit = false;
                    // this.goBack();
                    this.showSuccessModal = true;
                } else {
                    this.$bvToast.toast('Error submitting event attendance', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    this.loadingSubmit = false;

                }
            }
        }
        catch(err){
            console.log(err);
            
        }
        finally{
            this.loadingSubmit = false;
        }

        },
        deletePersonMultipleDay(id) {
            let index = this.foodSelected.multipleDays.otherPersons.findIndex(person => person.id === id);
            if (index !== -1) {
                this.foodSelected.multipleDays.otherPersons.splice(index, 1);
            }

        },
        addOtherPersonMultipleDays() {
            if (this.tempOtherPersonName) {
                this.foodSelected.multipleDays.otherPersons.push({
                    id: uuid(),
                    name: this.tempOtherPersonName,
                    attendingDays: [],
                    lunchByDay: [],
                    beverageByDay: []
                });
                this.tempOtherPersonName = "";
            }
        },
        deleteOtherPersonSingleDay(id) {
            this.foodSelected.singleDay.otherPersons = this.foodSelected.singleDay.otherPersons.filter(person => person.id !== id);
        },
        addOtherPersonSingleDay() {
            if (this.tempOtherPersonName) {
                this.foodSelected.singleDay.otherPersons.push({
                    id: uuid(),
                    name: this.tempOtherPersonName,
                    lunch: "",
                    beverage: "",
                });
                this.tempOtherPersonName = "";
            }
        },
        goBack() {
            this.$router.replace('/events')
        },
        async loadCurrentUser() {
            while (!this.$root.currentUser[0]) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        },
        getEventDetails() {
            return this.$route.params.eventId;
        },
        handleAttendingDaysChange(val) {
        this.hideNotAttending = val.length > 0;
    },
        async loadEventDetails() {
            try {
                await this.loadCurrentUser();
                let id = this.getEventDetails();
                
                if (id && this.$root.currentUser[0]) {
                    const clientID = this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid;
                    const caregiverID = this.$root.currentUser[0].crb5c_fow_caregiverid;
                    
                    const response = await axios.get(`/api/events/v2/getOne/${id}/${clientID}/${caregiverID}`);
                    
                    if (response.data.event) {
                        let jsonData = JSON.parse(response.data.event.crb5c_eventjson);
                        delete response.data.event.crb5c_eventjson;

                        this.event = {
                            ...response.data.event,
                            event: jsonData,
                            userSubmission: response.data.submission ? response.data.submission : {}
                        };

                        let submission = this.event.event?.submissions?.find(sub => sub.client === clientID && sub.caregiver === caregiverID);
                        let notAttending = this.event.event?.notAttending?.find(sub => sub.client === clientID && sub.caregiver === caregiverID);
                        
                        if (submission) {
                            this.event.submitted = true;
                            let submissionData = JSON.parse(this.event.userSubmission.crb5c_eventsubmissionjson);
                            this.event.userSubmission.data = submissionData;
                            delete this.event.userSubmission.crb5c_eventsubmissionjson;
                        } 
                        else if (notAttending) {
                            Object.assign(this.event, { submitted: true, notAttending: true });
                        }
                        else {
                            this.event.submitted = false;
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    },
    async mounted() {
        this.loading = true;
        await this.loadEventDetails();
        this.loading = false;
    },
    
    watch: {
    'event.event.isSingleDay': function (val) {
        if (!val) {
            // this.foodSelected.multipleDays.whoGoing.push('Client');
            // this.foodSelected.multipleDays.client.attendingDays = this.event.event.multipleEvents.map(day => new Date(day.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));
        }
    },
    'foodSelected.multipleDays.client.attendingDays': 'handleAttendingDaysChange',
    'foodSelected.multipleDays.caregiver.attendingDays': 'handleAttendingDaysChange',
    'foodSelected.multipleDays.otherPersons': 'handleAttendingDaysChange',
    'foodSelected.singleDay.whoGoing': function (val){
        if(!val.includes("Other Persons")){
            this.foodSelected.singleDay.otherPersons = [];
        }
        this.handleAttendingDaysChange(val)
    },
    //this function helps the user to unselect the dates by unselecting the whoGoing
    'foodSelected.multipleDays.whoGoing': function(val){
        if(val.length === 0){
            this.foodSelected.multipleDays.client.attendingDays = [];
            this.foodSelected.multipleDays.caregiver.attendingDays = [];
            this.foodSelected.multipleDays.otherPersons = this.foodSelected.multipleDays.otherPersons.map(person => {
                person.attendingDays = [];
                return person;
            });
        }
    }
},
}
</script>

<style lang="scss" scoped>
.loader {
    height: 50vh !important;
}

.badge-local {
    background-color: #8967b3;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
}

.text-detail {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;

    p {
        margin: 0;
        font-size: 0.875rem;
    }
}
</style>
